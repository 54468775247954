// import { useEffect, useState, forwardRef } from "react";
import { forwardRef } from "react";
// import { getSectionAttribute } from "../../api/services";
// import Spinner from "../../components/spinner/Spinner";
import BookAppointment from "../common/BookAppointment";

const HeroSection = forwardRef(({ sectionId, setWhatsapp }, ref) => {
  const data = {
    title: "Dr. Andrés Bernardo Acosta Flores",
    description:
      "Urgencias & fracturas generales; Cirugía especializada de hombro y rodilla.",
    image:
      "https://frontpage-assets.s3.amazonaws.com/acostaflores.com/frontpage/sections/cirugia-3.JPG",
    callToActions: [
      {
        _id: "6639bdfaae7d3c64d93e70d4",
        title: "Agendar cita",
        href: '<!-- Google Calendar Appointment Scheduling begin --> <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0pJsrtr8xbGYpj7P8AgXxxWDBkFwEvvC0HodMp_SbO3zuKyjJhDJTQGDMWKQDkgP4NTkGYpYDF?gv=true" style="border: 0" width="100%" height="600" frameborder="0"></iframe> <!-- end Google Calendar Appointment Scheduling -->',
      },
      {
        _id: "6639bee9cd950f187d025696",
        title: "Contacto",
        href: "https://api.whatsapp.com/send/?phone=528117484727&text=Buen+d%C3%ADa%2C+quisiera+agendar+una+cita.&type=phone_number&app_absent=0",
      },
    ],
    article: {
      _id: "6639bdfaae7d3c64d93e70d4",
      title: "",
      href: "",
      description: "Leer más",
    },
  };
  // const [isLoading, setIsLoading] = useState(true);

  // const fetchData = async () => {
  //   const response = await getSectionAttribute(sectionId);
  //   setData(response.data);
  //   setWhatsapp(response?.data?.callToActions?.[1]?.href);
  //   setIsLoading(false);
  // };

  // useEffect(() => {
  //   if (sectionId) {
  //     fetchData();
  //   }
  // }, [sectionId]);

  // if (isLoading) {
  //   return <Spinner />;
  // }

  return (
    <div ref={ref} className="relative">
      <div className="mx-auto max-w-7xl">
        <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
          <svg
            className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="0,0 90,0 50,100 0,100" />
          </svg>

          <div className="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-56 lg:pr-0">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
              <div className="hidden sm:mb-10 sm:flex">
                <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                  {data.article.title}{" "}
                  <a
                    href={data.article.href}
                    className="whitespace-nowrap font-semibold text-primary"
                  >
                    <span className="absolute inset-0" aria-hidden="true" />
                    {data.article.description}{" "}
                    <span aria-hidden="true">&rarr;</span>
                  </a>
                </div>
              </div>
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                {data.title}
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {data.description}
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <BookAppointment iframeUrl={data.callToActions[0].href} />
                <a
                  href={`https://api.whatsapp.com/send/?phone=${data.callToActions[1].href}&text=Buen+d%C3%ADa%2C+quisiera+agendar+una+cita.&type=phone_number&app_absent=0`}
                  className="text-sm font-semibold leading-6 text-secondary"
                >
                  {data.callToActions[1].title}{" "}
                  <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
          src={data.image}
          alt=""
        />
      </div>
    </div>
  );
});

export default HeroSection;
